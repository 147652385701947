@font-face {
  font-family: "Nexa TextRegular";
  src: url("./fonts/Fontfabric\ -\ Nexa\ Text\ Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Nexa Regular";
  src: url("./fonts/Fontfabric\ -\ Nexa\ Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Nexa TextBold";
  src: url("./fonts/Fontfabric\ -\ Nexa\ Text\ Bold.otf") format("opentype");
  font-weight: normal;
  font-style: bold;
}
@font-face {
  font-family: "Nexa Bold";
  src: url("./fonts/Fontfabric\ -\ Nexa\ Bold.otf") format("opentype");
  font-weight: normal;
  font-style: bold;
}
@font-face {
  font-family: "Nexa ExtraBold";
  src: url("./fonts/Fontfabric\ -\ Nexa\ Extra\ Bold.otf") format("opentype");
  font-style: bold;
}
@font-face {
  font-family: "Nexa TextExtraBold";
  src: url("./fonts/Fontfabric\ -\ Nexa\ Text\ Extra\ Bold.otf")
    format("opentype");
  font-style: bold;
}
@font-face {
  font-family: "Nexa Heavy";
  src: url("./fonts/Fontfabric\ -\ Nexa\ Heavy.otf") format("opentype");
}
@font-face {
  font-family: "Nexa Bold Italic";
  src: url("./fonts/Fontfabric\ -\ Nexa\ Bold\ Italic.otf") format("opentype");
}
@font-face {
  font-family: "Nexa TextRegularItalic";
  src: url("./fonts/Fontfabric\ - \Nexa\ Text\ Regular\ Italic.otf") format("opentype");
}

