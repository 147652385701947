.popupToastContainer {
  position: fixed;
  display: flex;
  align-items: center;
  opacity: 1;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  border-radius: 20px;
  background-color: #ffffff;
}
.popupToastContainer:focus {
  outline: none;
}
.popupToastContainer-enter {
  bottom: -10px;
  opacity: 0.5;
}
.popupToastContainer-enter-active {
  bottom: 30px;
  opacity: 1;
  transition: all 200ms;
}
.popupToastContainer-enter-done {
  bottom: 30px;
  opacity: 1;
}
.popupToastContainer-exit-done {
  bottom: -10px;
  opacity: 0;
}
.popupToastContainer-exit {
  bottom: 30px;
  opacity: 1;
}
.popupToastContainer-exit-active {
  bottom: -10px;
  opacity: 0;
  transition: all 200ms;
}
