.loginouter {
  background-image: linear-gradient(
    -45deg,
    #0956e6 0%,
    #0956e6 0%,
    #0956e6 100%
  );
  box-sizing: border-box;
  /* background-attachment: fixed; */
  width: 100%;
  display: flex;
  z-index: 1;
  /* min-height: 100%; */
  height: 100%;
  /* justify-content: center; */
  padding: 32px;

  /* align-items: center; */
  padding-top: 30%;
  padding-bottom: 20px;
}
.login-page-email {
  border-radius: 80px;
  background-color: #0745ba;
  border: 1px solid #0842b0;
  padding: 10px 20px;
  color: #ffffff;
  display: flex;
  margin-bottom: 24px;
  align-items: center;
}
.login-page-email > input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}
.login-page-password {
  border-radius: 80px;
  background-color: #0745ba;
  border: 1px solid #0842b0;
  padding: 10px 20px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.login-page-password > input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}
.MuiButton-root {
  font-weight: unset;
}

.popupToastContainer {
  position: fixed;
  display: flex;
  align-items: center;
  opacity: 1;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  border-radius: 20px;
  background-color: #ffffff;
}
.popupToastContainer:focus {
  outline: none;
}
/* @keyframes todayButtonAnimation {
	0% {
	  top: 0;
	  opacity: 0;
	}
	50% {
	  top: 140px;
	  opacity: 0.5;
	}
	100% {
	  top: 170px;
	  opacity: 1;
	}
  } */
.popupToastContainer-enter {
  bottom: -10px;
  opacity: 0.5;
}
.popupToastContainer-enter-active {
  bottom: 70px;
  opacity: 1;
  transition: all 200ms;
}
.popupToastContainer-enter-done {
  bottom: 70px;
  opacity: 1;
}
.popupToastContainer-exit-done {
  bottom: 0;
  opacity: 0;
}
.popupToastContainer-exit {
  bottom: 70px;
  opacity: 1;
}
.popupToastContainer-exit-active {
  bottom: -10px;
  opacity: 0.5;
  transition: all 200ms;
}
