.nexaTextRegular {
  font-family: Nexa TextRegular;
  font-style: normal;
  /* font-weight: bold; */
}
.nexaTextRegularItalic {
  font-family: Nexa TextRegular Italic;
  font-style: normal;
  /* font-weight: bold; */
}
.nexaRegular {
  font-family: Nexa Regular;
  font-style: normal;
  /* font-weight: bold; */
}
.nexaTextBold {
  font-family: Nexa TextBold;
  font-style: normal;
  /* font-weight: normal; */
}

.nexaBold {
  font-family: Nexa Bold;
  font-style: normal;
}
.nexaExtraBold {
  font-family: Nexa ExtraBold;
  font-style: normal;
  /* font-weight: normal; */
}
.nexaTextExtraBold {
  font-family: Nexa TextExtraBold;
  font-style: normal;
  /* font-weight: normal; */
}
.nexaHeavy {
  font-family: Nexa Heavy;
  font-style: normal;
  /* font-weight: normal; */
}
